import axios from 'axios'

const instance = axios.create({
    baseURL: 'https://www.taskicabs.com/adminbackend/servicePartner'
    // baseURL: 'https://staging.taskicabs.com/adminbackend/servicePartner'
    // baseURL: 'http://192.168.29.55:2020/adminbackend/servicePartner',
    // baseURL: 'http://10.114.20.165:2020/adminbackend/servicePartner',
    // baseURL: 'http://192.168.29.244:80/adminbackend/servicePartner',
})

const token = localStorage.getItem('vendorLoginToken');
if(token) {
    instance.defaults.headers.common['token'] = token
}
export default instance